import { createContext } from 'react';
import type { IReadRoleV2Dto, ReadUserDto } from '@goparrot/users-v2-sdk';
import { UseQueryResult } from 'react-query';

export type UserServiceContextType = {
  user: ReadUserDto;
  roleV2: IReadRoleV2Dto | undefined;
  roleV2QueryResult: UseQueryResult<IReadRoleV2Dto>;
};
export const UserServiceContext = createContext<UserServiceContextType>({} as UserServiceContextType);
