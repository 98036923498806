import * as React from 'react';
import { getIntlString, IntlMessage } from '@goparrot-dashboard/i18n';
import { ReadUserDto } from '@goparrot/users-v2-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { MarketBanner } from '@market/react-bindings';
import { isProductionEnv } from '@goparrot-dashboard/shared-utils';

interface State {
  hasError: boolean;
  error?: Error;
}
interface Props {
  user: ReadUserDto;
  children?: React.ReactNode;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      if (error.name === 'ChunkLoadError') {
        window.location.reload();
        return;
      }
      const errorData = {
        stack: error.stack,
        user: this.props.user,
      };
      if (isProductionEnv() && !!window._env_.DD_CLIENT_TOKEN) {
        datadogLogs.logger.error(error.name, { errorData });
      }

      return (
        <MarketBanner variant="warning" className="tw-w-3/4 tw-mt-10 tw-ml-10 tw-max-w-4xl">
          <span slot="title">{getIntlString('component.warning.title')}</span>
          <IntlMessage
            id={'component.warning.message'}
            values={{
              link: (chunks: string) => <a href={'https://squareup.com/help/us/en/contact'}>{chunks}</a>,
            }}
          />
        </MarketBanner>
      );
    }

    return this.props.children;
  }
}
