import { I18nService } from '@goparrot-dashboard/i18n';
import React, { FC, useCallback, useMemo } from 'react';
import { checkObjectsAreDifferent } from '../../utils';
import ClearAllButton from './ClearAllButton';
import { CATEGORIES_FILTER_KEY, DEFAULT_UNCATEGORIZED_FILTER, UNCATEGORIZED } from './constants';
import { FilterItem } from './FilterItem';
import { FilterSettingsItem, FilterSettingsType, FiltersProps } from './interface';

const FiltersComponent: FC<React.PropsWithChildren<FiltersProps>> = ({
  disabled = false,
  isFilterOptionDisabled = false,
  hideClearAllButton = false,
  filters,
  setFilters,
  defaultFilters = [],
  setFiltersRefs,
}) => {
  const selectAllOption = useCallback(
    (filterToUpdate: FilterSettingsType) => {
      const newFilters = filters.map((filter) => {
        if (filter.field === filterToUpdate.field) {
          if (filter.selectedItems.length) {
            return { ...filter, selectedItems: [] };
          }
          if (filterToUpdate.field === CATEGORIES_FILTER_KEY) {
            return { ...filter, selectedItems: filter.items.filter((item) => item.value !== UNCATEGORIZED) };
          }
          return { ...filter, selectedItems: filter.items };
        }
        return filter;
      });
      setFilters(newFilters);
    },
    [filters, setFilters],
  );

  const toggleFilterOption = useCallback(
    ({ filterToUpdate, itemToUpdate }: { filterToUpdate: FilterSettingsType; itemToUpdate: FilterSettingsItem }) => {
      const newFilters = filters.map((filter) => {
        if (filter.field === filterToUpdate.field) {
          if (filter.selectedItems.some((item) => item.value === itemToUpdate.value)) {
            const newSelectedItems = filter.selectedItems.filter((item) => item.value !== itemToUpdate.value);
            return { ...filter, selectedItems: newSelectedItems };
          }
          if (itemToUpdate.value === UNCATEGORIZED) {
            return { ...filter, selectedItems: [DEFAULT_UNCATEGORIZED_FILTER] };
          }
          return { ...filter, selectedItems: [...filter.selectedItems.filter((item) => item.value !== UNCATEGORIZED), itemToUpdate] };
        }
        return filter;
      });
      setFilters(newFilters);
    },
    [filters, setFilters],
  );

  const hasChangedFilters = useMemo(() => {
    return checkObjectsAreDifferent(filters, defaultFilters);
  }, [filters, defaultFilters]);

  return (
    <>
      {filters.map((filter) =>
        filter.items.length > 0 ? (
          <FilterItem
            key={filter.field}
            disabled={disabled}
            filter={filter}
            toggleFilterOption={toggleFilterOption}
            selectAllOption={selectAllOption}
            setFiltersRefs={setFiltersRefs}
            isFilterOptionDisabled={isFilterOptionDisabled}
          />
        ) : null,
      )}
      <ClearAllButton
        visible={hasChangedFilters && !hideClearAllButton}
        disabled={disabled || isFilterOptionDisabled}
        setFilters={(items: FilterSettingsType[]) => setFilters(items)}
        defaultFilters={defaultFilters}
      />
    </>
  );
};

export const Filters: React.FC<React.PropsWithChildren<FiltersProps>> = (props) => (
  <I18nService prefix="menu.list.filters">
    <FiltersComponent {...props} />
  </I18nService>
);
