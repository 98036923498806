import { call, put, fork, take, takeLatest } from 'redux-saga/effects';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';

import { Socket } from 'socket.io-client';

import aApp from '../app/actions';
import auth from '../auth/actions';

import { socketChannel } from './channels/socketChannel';
import { createSocketConnection } from './api';
import aWs from './actions';

interface AuthPayload {
  merchantId: string;
}

interface Action<TPayload> {
  type: string;
  payload: TPayload;
}

interface RequestItem {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  type: StoreItemTypeEnum;
}

function* readSocket(socket: Socket, payload) {
  const channel = yield call(socketChannel, socket, payload.jobId); // channel which subscribes to socket event
  while (true) {
    try {
      const action = yield take(channel); // wait response
      const { uniqueName } = action?.payload;
      const data = JSON.parse(sessionStorage.getItem(uniqueName));
      yield put(action);
      if (data?.title) {
        yield put(aApp.pushMenuSyncNotificationWithSuccess({ ...action?.payload, title: data?.title }));
      }
      yield sessionStorage.removeItem(uniqueName);
    } catch (e) {
      channel.close(); // stop listen socket channel
      yield put(aApp.pushNotificationWithError('menu.sync.error.message', 'menu.sync.error.description'));
    }
  }
}

export default function* watcherWebsocket() {
  yield takeLatest(auth.API__AUTH__LOGIN__SUCCESS, function* (action: Action<AuthPayload>) {
    const { payload } = action;
    const token: string = yield localStorage.getItem('token');
    const socket = yield call(createSocketConnection, payload.merchantId, token);
    while (true) {
      const { payload } = yield take(aWs.ITEM__SYNC__JOB__EMIT);
      yield fork(readSocket, socket, payload);
    }
  });
}
