import { generatePath, match, RouterChildContext } from 'react-router';
import { RouteParams } from '../interface';

const BASE_MENU_PATH = '/dashboard/menu-management';

export const redirectToStoreLevel = (link: string, storeId: string, match: match<RouteParams>, history: RouterChildContext['router']['history']) => {
  if (match.url.includes(`${BASE_MENU_PATH}/`)) {
    const { itemType } = match.params;

    history.push(generatePath(link, { id: storeId, itemType }));
  } else {
    history.push(generatePath(link, { id: storeId }));
  }
};
