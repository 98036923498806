// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-device-width: 768px)and (max-device-width: 1024px)and (orientation: landscape){.GmwRX7UoWxfGqtMr9ItT{height:668px}}@media only screen and (min-device-width: 768px)and (max-device-width: 1024px)and (orientation: portrait){.GmwRX7UoWxfGqtMr9ItT{height:1024px}}.GmwRX7UoWxfGqtMr9ItT .trigger{font-size:18px;line-height:64px;padding:0 16px;cursor:pointer;transition:color .3s}.GmwRX7UoWxfGqtMr9ItT .trigger:hover{color:#4482ff}.GmwRX7UoWxfGqtMr9ItT .ant-layout-sider-collapsed .anticon{font-size:16px}.GmwRX7UoWxfGqtMr9ItT .ant-layout-sider-collapsed .nav-text{display:none}.GmwRX7UoWxfGqtMr9ItT .ant-layout{background:#fff}@media only screen and (max-width: 1279px){.GmwRX7UoWxfGqtMr9ItT .ant-layout.Ioug26dZ2aq5LN1q0i7s{width:100%;flex-shrink:0}}@media only print{.GmwRX7UoWxfGqtMr9ItT .TyYmuPOkQcWZHlkXUIsV{position:relative !important}.GmwRX7UoWxfGqtMr9ItT .Ioug26dZ2aq5LN1q0i7s{height:auto !important}}.GmwRX7UoWxfGqtMr9ItT .ant-layout-footer{font-size:13px;min-width:942px}@media(max-width: 1279px){.GmwRX7UoWxfGqtMr9ItT .ant-layout-footer{padding:10px 20px}}.GmwRX7UoWxfGqtMr9ItT .F47zHXHUDdBfutB0kfIB{flex-shrink:0;background:#fff;padding:66px 0 0}@media(max-width: 1279px){.GmwRX7UoWxfGqtMr9ItT .F47zHXHUDdBfutB0kfIB{padding:56px 0 0}}.GmwRX7UoWxfGqtMr9ItT button{border-radius:1}", "",{"version":3,"sources":["webpack://./modules/client/src/components/containers/styles/app.module.scss"],"names":[],"mappings":"AACE,2GADF,sBAEI,YAAA,CAAA,CAEF,0GAJF,sBAKI,aAAA,CAAA,CAEF,+BACE,cAAA,CACA,gBAAA,CACA,cAAA,CACA,cAAA,CACA,oBAAA,CACA,qCACE,aAAA,CAKF,2DACE,cAAA,CAEF,4DACE,YAAA,CAIJ,kCACE,eAAA,CAGE,2CADF,uDAEI,UAAA,CACA,aAAA,CAAA,CAMN,kBACE,4CACE,4BAAA,CAGF,4CACE,sBAAA,CAAA,CAIJ,yCACE,cAAA,CACA,eAAA,CACA,0BAHF,yCAII,iBAAA,CAAA,CAIJ,4CACE,aAAA,CACA,eAAA,CACA,gBAAA,CACA,0BAJF,4CAKI,gBAAA,CAAA,CAIJ,6BACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GmwRX7UoWxfGqtMr9ItT",
	"mainLayout": "Ioug26dZ2aq5LN1q0i7s",
	"appLayout": "TyYmuPOkQcWZHlkXUIsV",
	"content": "F47zHXHUDdBfutB0kfIB"
};
module.exports = ___CSS_LOADER_EXPORT___;
