import type { ImportValidationResponseDto } from '@goparrot/customer-sdk';
import type FormData from 'form-data';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { userImportFacade } from '../facades';

enum ImportTypesEnum {
  TOAST = 'toast',
  REGULAR = 'regular',
}

type ImportTypeParam = {
  type: ImportTypesEnum;
};

type CustomersImportPayload = {
  formData: FormData;
  file: File;
  params: ImportTypeParam;
};

type PatchUserParams = {
  merchantId: string;
  data: CustomersImportPayload;
};

export const useUploadCSV = (
  options?: UseMutationOptions<ImportValidationResponseDto, unknown, PatchUserParams>,
): UseMutationResult<ImportValidationResponseDto, unknown> => {
  return useMutation(({ merchantId, data }) => userImportFacade.upload(merchantId, data.formData, data.params), options);
};
